import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header3 from "../../assets/header3.png";
import header3Mobile from "../../assets/mobile/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            TUDO PARA <br />
            <span>COIFAS</span>
          </h1>

          <p className="text">
            Instalação, manutenção e peças de reposição de coifas residenciais,
            nacionais e importadas.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <p className="text">
            Temos a solução do que você precisa em um só lugar.{" "}
            <b> EXPERIÊNCIA E COMPROMETIMENTO!</b>
          </p>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <figure className="left">
            <img src={header3} alt="Homem colocando Coifa" />
          </figure>

          <div className="right">
            <h2 className="title">
              Soluções em instalação de sistemas de exaustão para cozinhas
              residenciais.
            </h2>

            <figure className="mobile">
              <img src={header3Mobile} alt="Homem colocando Coifa" />
            </figure>

            <p className="text">
              Instalação e Manutenção preventiva de coifas residenciais
              nacionais e importadas, e sistemas para controle de incêndio
              devido ao acúmulo de gorduras.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
