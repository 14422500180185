import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about3 from "../../assets/about3.png";
import about3Mobile from "../../assets/mobile/about3.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_1Mobile from "../../assets/mobile/about3_1.png";
import about3_2Mobile from "../../assets/mobile/about3_2.png";
import about3_3Mobile from "../../assets/mobile/about3_3.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about2_1,
  },
  {
    id: 2,
    image: about2_2,
  },
  {
    id: 3,
    image: about2_3,
  },
  {
    id: 4,
    image: about2_4,
  },
  {
    id: 5,
    image: about2_5,
  },
];

const data2 = [
  {
    id: 1,
    image: about3_1,
    imageMobile: about3_1Mobile,
  },
  {
    id: 2,
    image: about3_2,
    imageMobile: about3_2Mobile,
  },
  {
    id: 3,
    image: about3_3,
    imageMobile: about3_3Mobile,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">O que podemos fazer por você?</h2>

          <p className="text">
            Entregamos sempre entregar um serviço de qualidade, visando garantir
            a satisfação dos nossos clientes.
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Manutenção Preventiva" />
              </figure>

              <p className="item_title">Manutenção Preventiva</p>

              <p className="item_text">
                ​A manutenção é extremamente necessária pois o sistema é
                vulnerável à incêndios, devido ao acúmulo de gordura inflamável,
                e mantendo níveis regulares de temperatura proporcionando também
                a higiene necessária para manipulação de alimentos.
              </p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="Cozinhas Residenciais" />
              </figure>

              <p className="item_title">Cozinhas Residenciais</p>

              <p className="item_text">
                Contamos com uma equipe especializada em ​Instalação,
                Manutenção, Assistência Técnica e Restauração de Coifas para
                cozinhas residenciais.
              </p>

              <CtaWpp />
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Serviços realizados</h2>

          <p className="text">
            Veja agora alguns dos serviços que já realizamos
          </p>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 3
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">
            O que nossos clientes falam sobre o nosso serviço:
          </h2>

          <div className="gallery">
            <figure className="left" t>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? about3Mobile
                    : about3
                }
                alt="Google"
              />
            </figure>

            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data2.map(({ id, image, imageMobile }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img
                        src={
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                          )
                            ? imageMobile
                            : image
                        }
                        alt={`Exemplo ${id} de Foto`}
                      />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <div className="left">
            <div className="title">Saiba onde nos encontrar:</div>

            <p className="text">
              Rua Apolonia Pinto, 76 Tanque Jacarepaguá, Rio de Janeiro - RJ CEP
              22.735-290.
            </p>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.695831742925!2d-43.367164121749376!3d-22.924587284962797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd85c0dcd0b71%3A0x66796c72098fbed8!2sR.%20Apol%C3%B4nia%20Pinto%2C%2076%20-%20Tanque%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022735-290!5e0!3m2!1spt-BR!2sbr!4v1715976835835!5m2!1spt-BR!2sbr"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map"
              className="map"
            ></iframe>
          </div>

          <div className="right">
            <h2 className="title">Horário de atendimento:</h2>

            <p className="text">
              <b>Segunda à sexta-feira: </b>07:00h às 20:00h. <br />
              <b>Sábado: </b>07:00h às 18:00h.
            </p>

            <h2 className="title title2">
              Solicite seu orçamento ou tire suas dúvidas pelo nosso Telefone ou
              WhatsApp:
            </h2>

            <div className="cta">
              <CtaTel />
              <CtaWpp text={"(21) 97906-4496"} />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
